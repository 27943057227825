import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const newsCarousels = document.querySelectorAll(
    '.last-news-carousel-block__carousel'
  );

  if (0 < newsCarousels.length) {
    newsCarousels.forEach((carousel) => {
      new Swiper(carousel, {
        slidesPerView: 1,
        loop: true,
        navigation: {
          prevEl: carousel.querySelector('.swiper-button-prev'),
          nextEl: carousel.querySelector('.swiper-button-next'),
        },
        pagination: {
          el: carousel.querySelector('.swiper-pagination'),
          type: 'fraction',
        },
        a11y: {
          prevSlideMessage: _x(
            'See previous post',
            'slider previous label',
            'vtx'
          ),
          nextSlideMessage: _x('See next post', 'slider next label', 'vtx'),
          slideLabelMessage: _x(
            'Slide {{index}} of {{slidesLength}}',
            'slide label',
            'vtx'
          ),
        },

        breakpoints: {
          600: {
            slidesPerView: 1.6,
            spaceBetween: 30,
          },
          960: {
            spaceBetween: 40,
            slidesPerView: 2,
          },
          1080: {
            spaceBetween: 40,
            slidesPerView: 3,
          },
        },
      });
    });
  }
});
